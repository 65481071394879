<template>
	<w-dialog
		v-if="connection"
		v-model="active"
		:title="
			$t('bill-scrapper.automations.pending_connection_auth_dialog', { name: connection.provider.name + (connection.label ? ` (${connection.label})` : '') })
		"
		max-width="80%"
		min-height="60vh"
	>
		<w-flex v-if="loader" style="display: flex; align-items: center; justify-content: center; height: 100%; width: 100%">
			<v-progress-circular color="primary" indeterminate />
		</w-flex>
		<w-flex v-else style="display: flex; flex-direction: column; align-items: flex-start; height: 100%; width: 100%"
			><w-alert :value="true" color="warning" icon="warning" style="word-break: break-word; width: 100%">
				{{ $t('bill-scrapper.automations.text_webview_pending_connection') }}
				<a :href="connection.url" style="color: #ffffff; font-weight: bold" target="_blank">{{
					$t('bill-scrapper.automations.button_webview_pending_connection')
				}}</a>
			</w-alert>
			<iframe
				:src="connection.url"
				:title="
					$t('bill-scrapper.automations.pending_connection_auth_dialog', {
						name: connection.provider.name + (connection.label ? ` (${connection.label})` : '')
					})
				"
				style="border: 0; width: 100%; height: 99%"
			></iframe>
		</w-flex>
		<template v-slot:actions>
			<w-flex pb-2 pl-2 text-xs-left>
				<w-btn outline @click="cancel">{{ $t('bill-scrapper.automations.cancel_pending_connection') }}</w-btn>
			</w-flex>
			<w-flex pb-2 pr-2 text-xs-right>
				<w-btn @click="active = false">{{ $t('actions.done') }}</w-btn>
			</w-flex>
		</template>
	</w-dialog>
</template>

<script>
import BillScrapperModuleGuard from '@/mixins/ModulesGuards/BillScrapper/BillScrapperModuleGuard'

export default {
	name: 'PendingConnectionWebview',
	mixins: [BillScrapperModuleGuard],
	data: function () {
		return {
			active: false,
			loader: false,
			connection: null
		}
	},
	computed: {},
	watch: {
		active: function (newVal, oldVal) {
			if (oldVal && !newVal && this.connection !== null) {
				this.eventBus.emit(this.events.CLOSE_PENDING_CONNECTION_WEBVIEW, this.connection)
				this.clear()
			}
		}
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.SHOW_PENDING_CONNECTION_WEBVIEW, action: this.onShowPendingConnectionWebView },
				{ event: this.events.PENDING_CONNECTION_CANCELED, action: this.onPendingConnectionCanceled },
				{ event: this.events.CONNECTION_CREATED, action: this.onConnectionCreated }
			]
		},
		/**
		 * @param {object} pendingConnection
		 */
		onShowPendingConnectionWebView: function (pendingConnection) {
			this.connection = pendingConnection
			this.active = true
			this.loader = false
		},
		onPendingConnectionCanceled: function () {
			this.clear()
		},
		onConnectionCreated: function () {
			this.clear()
		},
		clear: function () {
			this.active = false
			this.connection = null
			this.loader = false
		},
		cancel: function () {
			this.loader = true
			this.eventBus.emit(this.events.CANCEL_PENDING_CONNECTION_WEBVIEW, this.connection)
		}
	}
}
</script>
